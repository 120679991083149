import React, { SVGProps } from 'react';

const SvgSignalWifiStatusbarConnectedNoInternet = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg viewBox='0 0 24 24' fill='currentColor' className='svg-icon' {...props}>
            <path d='M0 0h24v24H0V0z' fill='none' />
            <path d='M19 18h2v2h-2zM19 10h2v6h-2z' />
            <path
                d='M17 8h5.92C19.97 5.51 16.16 4 12 4 7.31 4 3.07 5.9 0 8.98L12 21l5-5.01V8z'
                opacity={0.3}
            />
        </svg>
    );
};

export default SvgSignalWifiStatusbarConnectedNoInternet;
