import React, { SVGProps } from 'react';

const SvgSpaceDashboard = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg viewBox='0 0 24 24' fill='currentColor' className='svg-icon' {...props}>
            <path fill='none' d='M0 0h24v24H0z' />
            <path d='M5 19V5h6v14H5zm14 0h-6v-7h6v7zm0-9h-6V5h6v5z' opacity={0.3} />
            <path d='M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zM5 19V5h6v14H5zm14 0h-6v-7h6v7zm0-9h-6V5h6v5z' />
        </svg>
    );
};

export default SvgSpaceDashboard;
