import React, { SVGProps } from 'react';

const SvgTouchApp = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg viewBox='0 0 24 24' fill='currentColor' className='svg-icon' {...props}>
            <path fill='none' d='M0 0h24v24H0z' />
            <path d='M18.19 12.44l-3.24-1.62c1.29-1 2.12-2.56 2.12-4.32 0-3.03-2.47-5.5-5.5-5.5s-5.5 2.47-5.5 5.5c0 2.13 1.22 3.98 3 4.89v3.26c-2.08-.44-2.01-.44-2.26-.44-.53 0-1.03.21-1.41.59L4 16.22l5.09 5.09c.43.44 1.03.69 1.65.69h6.3c.98 0 1.81-.7 1.97-1.67l.8-4.71c.22-1.3-.43-2.58-1.62-3.18zM8.07 6.5c0-1.93 1.57-3.5 3.5-3.5s3.5 1.57 3.5 3.5c0 .95-.38 1.81-1 2.44V6.5a2.5 2.5 0 00-5 0v2.44c-.62-.63-1-1.49-1-2.44zm9.77 8.79l-.8 4.71h-6.3c-.09 0-.17-.04-.24-.1l-3.68-3.68 4.25.89V6.5c0-.28.22-.5.5-.5s.5.22.5.5v6h1.76l3.46 1.73c.4.2.62.63.55 1.06z' />
            <path
                d='M17.3 14.23l-3.46-1.73h-1.77v-6c0-.28-.22-.5-.5-.5s-.5.22-.5.5v10.61l-4.25-.89 3.68 3.68c.06.06.15.1.24.1h6.3l.8-4.71c.07-.43-.15-.86-.54-1.06z'
                opacity={0.3}
            />
        </svg>
    );
};

export default SvgTouchApp;
