import React, { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { IUserProps } from '../common/data/interfaces';

export interface IAuthContextProps {
	user: string;
	setUser?(...args: unknown[]): unknown;
	userData: Partial<IUserProps>;
	msgUnreadCount: string;
	setMsgUnreadCount?(...args: unknown[]): unknown;
}

// interface IServiceProps {
// 	name: string;
// 	icon: string;
// 	color: TColor;
// }

const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);

interface IAuthContextProviderProps {
	children: ReactNode;
}


export const AuthContextProvider: FC<IAuthContextProviderProps> = ({ children }) => {
  const [ user, setUser ] = useState<string>(localStorage.getItem('authUserFsNm') || '');
  const [ userData ] = useState<Partial<IUserProps>>({});
  const [ msgUnreadCount, setMsgUnreadCount ] = useState<string>(localStorage.getItem('unreadCount') || '0');

  useEffect(() => {
    setUserDetails();
  }, [user]);

  useEffect(() => {
    //localStorage.setItem('facit_authUsername', user);
    localStorage.setItem('authUserFsNm', user);
  }, [user]);

  const setUserDetails = async () =>{
    if(user !== ''){
      // Remove the empty block
    } else {
      localStorage.setItem('authUserId', '');
		    localStorage.setItem('authUserEmail', '');
		    localStorage.setItem('authUserPic', '');
      localStorage.setItem('authUserFsNm', '');
		    localStorage.setItem('authUserLsNm', '');
      //localStorage.setItem('locationId','');
      localStorage.setItem('unreadCount','0');
      localStorage.setItem('startTime', '');
      //sessionStorage.removeItem('locationId');
    }
  };

  const value = useMemo(
    () => ({
      user,
      setUser,
      userData,
      msgUnreadCount,
      setMsgUnreadCount
    }),
    [user, userData, msgUnreadCount],
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
AuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
