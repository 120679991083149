import React, { lazy } from 'react';
import { RouteProps } from 'react-router-dom';
import {
  demoPagesMenu
} from '../menu';
import Login from '../pages/presentation/auth/Login';
import AdminLogin from '../pages/presentation/auth/LoginAdmin';

const PRICING = {
  PRICING_TABLE: lazy(() => import('../pages/presentation/pricing/PricingTablePage')),
};

const SESSION = {
  SESSION_LIST: lazy(() => import('../pages/presentation/session/sessionList')),
};

const SINGLE = {
  FLUID: lazy(() => import('../pages/presentation/location/LocationPage')),
  BOXED: lazy(() => import('../pages/presentation/location/LocationList')),
};

const AUTH = {
  PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
};
const APP = {
  SALES: {
    TRANSACTIONS: lazy(() => import('../pages/presentation/sales/TransActionsPage')),
  },
  FRIENDS: {
    FRIEND_LIST: lazy(() => import('../pages/presentation/friends/FriendsList')),
    FRIEND_DETAIL: lazy(() => import('../pages/presentation/friends/FriendDetails')),
  },
  CHAT: {
    ONLY_LIST: lazy(() => import('../pages/presentation/chat/OnlyListChatPage')),
  },
};
const PROFILE = {
  EDIT: lazy(() => import('../pages/presentation/profile/EditProfile')),
};
// const PAGE_LAYOUTS = {
//     ASIDE: lazy(() => import('../pages/presentation/aside-types/DefaultAsidePage')),
//     MINIMIZE_ASIDE: lazy(() => import('../pages/presentation/aside-types/MinimizeAsidePage')),
// };

const presentation: RouteProps[] = [
  /** ************************************************** */

  /**
	 * Pages
	 */

  {
    path: demoPagesMenu.pricingTable.path,
    element: <PRICING.PRICING_TABLE />,
  },

  {
    path: demoPagesMenu.sessionList.path,
    element: <SESSION.SESSION_LIST />,
  },

  /**
	 * Auth Page
	 */
  {
    path: demoPagesMenu.page404.path,
    element: <AUTH.PAGE_404 />,
  },
  {
    path: demoPagesMenu.login.path,
    element: <Login />,
  },
  {
    path: demoPagesMenu.adminLogin.path,
    element: <AdminLogin />,
  },
  {
    path: demoPagesMenu.signUp.path,
    element: <Login isSignUp />,
  },

  /**
	 * App
	 */

  /**
	 * App > Sales
	 */
  {
    path: demoPagesMenu.sales.subMenu.transactions.path,
    element: <APP.SALES.TRANSACTIONS />,
  },

  /**
	 * App > Friend
	 */
  {
    //path: demoPagesMenu.appointment.subMenu.friendsList.path,
    path: `${demoPagesMenu.friend.subMenu.friendsList.path}/:id`,
    element: <APP.FRIENDS.FRIEND_LIST />,
  },
  {
    path: `${demoPagesMenu.friend.subMenu.employeeID.path}/:id`,
    element: <APP.FRIENDS.FRIEND_DETAIL />,
  },

  {
    path: `${demoPagesMenu.location.subMenu.fluidSingle.path}/:id`,
    element: <SINGLE.FLUID />,
  },

  {
    path: demoPagesMenu.location.subMenu.boxedSingle.path,
    element: <SINGLE.BOXED />,
  },

  /**
	 * App > CRM
	 */
	
  {
    path: demoPagesMenu.editPages.subMenu.editFluid.path,
    element: <PROFILE.EDIT />,
  },

  /**
	 * App > Chat
	 */
  {
    path: demoPagesMenu.chat.subMenu.onlyListChat.path,
    element: <APP.CHAT.ONLY_LIST />,
  },

  /**
	 * END - App
	 */

  /** ************************************************** */

];

const contents = [...presentation];

export default contents;
