import React, { FC, ReactNode, useState, useContext } from 'react';
import PropTypes from 'prop-types';
// import classNames from 'classnames';
import Button, { IButtonProps } from '../../../components/bootstrap/Button';
import { HeaderRight } from '../../../layout/Header/Header';
import OffCanvas, {
  OffCanvasBody,
  OffCanvasHeader,
  OffCanvasTitle,
} from '../../../components/bootstrap/OffCanvas';
import Alert from '../../../components/bootstrap/Alert';
import Icon from '../../../components/icon/Icon';
//import showNotification from '../../../components/extras/showNotification';
import useDarkMode from '../../../hooks/useDarkMode';
import Popovers from '../../../components/bootstrap/Popovers';
import { demoPagesMenu } from '../../../menu';
import { deleteDataAPI } from '../../../helpers/httpUtils';
import AuthContext from '../../../contexts/authContext';
import { useNavigate } from 'react-router-dom';

interface ICommonHeaderRightProps {
	beforeChildren?: ReactNode;
	afterChildren?: ReactNode;
}
const CommonHeaderRight: FC<ICommonHeaderRightProps> = ({ beforeChildren, afterChildren }) => {
  const { setUser } = useContext(AuthContext);
  const { darkModeStatus, setDarkModeStatus } = useDarkMode();
  const navigate = useNavigate();

  const styledBtn: IButtonProps = {
    color: darkModeStatus ? 'dark' : 'light',
    hoverShadow: 'default',
    isLight: !darkModeStatus,
    size: 'lg',
  };

  const [offcanvasStatus, setOffcanvasStatus] = useState(false);

  // /**
  //  * Language attribute
  //  */
  // useLayoutEffect(() => {
  // 	document.documentElement.setAttribute('lang', i18n.language.substring(0, 2));
  // });

  const logoutUser = async() => {
    const res =  await deleteDataAPI('/auth/logout');
    if(res.data.statusCode === 200){
      if (setUser) {
        setUser('');
      }
      await localStorage.removeItem('currentUserDetails');
      if(localStorage.getItem('isAdmin')){
        await navigate(`../${demoPagesMenu.adminLogin.path}`);
      } else {
        await navigate(`../${demoPagesMenu.login.path}`);
      }
      // await navigate(`../${demoPagesMenu.login.path}`);
    }
  };

  return (
    <HeaderRight>
      <div className='row g-3'>
        {beforeChildren}
				
        {/* Dark Mode */}
        <div className='col-auto'>
          <Popovers trigger='hover' desc='Dark / Light mode'>
            <Button
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...styledBtn}
              onClick={() => setDarkModeStatus(!darkModeStatus)}
              className='btn-only-icon'
              data-tour='dark-mode'>
              <Icon
                icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
                color={darkModeStatus ? 'info' : 'warning'}
                className='btn-icon'
              />
            </Button>
          </Popovers>
        </div>

        {/*	Notifications */}
        {/* <div className='col-auto'>
					<Button
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...styledBtn}
						icon='Notifications'
						onClick={() => setOffcanvasStatus(true)}
						aria-label='Notifications'
					/>
				</div> */}

        {/* Logout */}
        <div className='col-auto'>
				    <Button
            icon="Logout"
            color="danger"
            className="mt-1"
            isLight
            onClick={() => logoutUser()}
          >
                    Logout
          </Button>
		        </div>
        {afterChildren}
      </div>

      <OffCanvas
        id='notificationCanvas'
        titleId='offcanvasExampleLabel'
        placement='end'
        isOpen={offcanvasStatus}
        setOpen={setOffcanvasStatus}>
        <OffCanvasHeader setOpen={setOffcanvasStatus}>
          <OffCanvasTitle id='offcanvasExampleLabel'>Notifications</OffCanvasTitle>
        </OffCanvasHeader>
        <OffCanvasBody>
          <Alert icon='ViewInAr' isLight color='info' className='flex-nowrap'>
						4 new components added.
          </Alert>
          <Alert icon='ThumbUp' isLight color='warning' className='flex-nowrap'>
						New products added to stock.
          </Alert>
          <Alert icon='Inventory2' isLight color='danger' className='flex-nowrap'>
						There are products that need to be packaged.
          </Alert>
          <Alert icon='BakeryDining' isLight color='success' className='flex-nowrap'>
						Your food order is waiting for you at the consultation.
          </Alert>
          <Alert icon='Escalator' isLight color='primary' className='flex-nowrap'>
						Escalator will turn off at 6:00 pm.
          </Alert>
        </OffCanvasBody>
      </OffCanvas>
    </HeaderRight>
  );
};
CommonHeaderRight.propTypes = {
  beforeChildren: PropTypes.node,
  afterChildren: PropTypes.node,
};
CommonHeaderRight.defaultProps = {
  beforeChildren: null,
  afterChildren: null,
};

export default CommonHeaderRight;
