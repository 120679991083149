import React, { SVGProps } from 'react';

const SvgDialerSip = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg viewBox='0 0 24 24' fill='currentColor' className='svg-icon' {...props}>
            <path d='M0 0h24v24H0V0z' fill='none' />
            <path
                d='M15.2 18.21c1.2.41 2.48.67 3.8.75v-1.5c-.88-.06-1.75-.22-2.59-.45l-1.21 1.2zM6.54 5h-1.5c.09 1.32.35 2.59.75 3.79l1.2-1.21c-.24-.83-.39-1.7-.45-2.58z'
                opacity={0.3}
            />
            <path d='M16 3h1v5h-1zm-4 4v1h3V5h-2V4h2V3h-3v3h2v1zm9-4h-3v5h1V6h2V3zm-1 2h-1V4h1v1zm1 11.5c0-.55-.45-1-1-1-1.25 0-2.45-.2-3.57-.57a.984.984 0 00-1.01.24l-2.2 2.2a15.045 15.045 0 01-6.59-6.59l2.2-2.21c.27-.26.35-.65.24-1A11.36 11.36 0 018.5 4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1 0 9.39 7.61 17 17 17 .55 0 1-.45 1-1v-3.5zM5.03 5h1.5c.07.88.22 1.75.46 2.59L5.79 8.8c-.41-1.21-.67-2.48-.76-3.8zM19 18.97c-1.32-.09-2.59-.35-3.8-.75l1.2-1.2c.85.24 1.71.39 2.59.45v1.5z' />
        </svg>
    );
};

export default SvgDialerSip;
