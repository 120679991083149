import React from 'react';
import { RouteProps } from 'react-router-dom';
import {
  componentPagesMenu,
  demoPagesMenu,
} from '../menu';
import DashboardHeader from '../pages/_layout/_headers/DashboardHeader';
import DashboardBookingHeader from '../pages/_layout/_headers/DashboardBookingHeader';
import ProfilePageHeader from '../pages/_layout/_headers/ProfilePageHeader';
import ProductsHeader from '../pages/_layout/_headers/ProductsHeader';
import ProductListHeader from '../pages/_layout/_headers/ProductListHeader';
import ComponentsHeader from '../pages/_layout/_headers/ComponentsHeader';
import FormHeader from '../pages/_layout/_headers/FormHeader';
import ContentHeader from '../pages/_layout/_headers/ContentHeader';
import IconHeader from '../pages/_layout/_headers/IconHeader';

const headers: RouteProps[] = [
  { path: demoPagesMenu.login.path, element: null },
  { path: demoPagesMenu.adminLogin.path, element: null },
  { path: demoPagesMenu.signUp.path, element: null },
  // { path: demoPagesMenu.page404.path, element: null },
  {
    path: demoPagesMenu.projectManagement.subMenu.list.path,
    element: <DashboardHeader />,
  },
  { path: demoPagesMenu.pricingTable.path, element: <DashboardBookingHeader /> },
  {
    path: demoPagesMenu.friend.subMenu.calendar.path,
    element: <DashboardBookingHeader />,
  },
  {
    //path: demoPagesMenu.friend.subMenu.friendsList.path,
    path: `${demoPagesMenu.friend.subMenu.friendsList.path}/:id`,
    element: <DashboardBookingHeader />,
  },
  {
    path: demoPagesMenu.listPages.subMenu.listFluid.path,
    element: <DashboardBookingHeader />,
  },
  {
    path: `${demoPagesMenu.editPages.path}/*`,
    element: <DashboardBookingHeader />,
  },
  {
    path: `${demoPagesMenu.friend.subMenu.employeeID.path}/*`,
    element: <DashboardBookingHeader />,
  },
  {
    path: `${demoPagesMenu.projectManagement.subMenu.itemID.path}/*`,
    element: <DashboardBookingHeader />,
  },
  {
    path: demoPagesMenu.location.subMenu.fluidSingle.path,
    element: <ProfilePageHeader />,
  },
  {
    path: demoPagesMenu.location.subMenu.boxedSingle.path,
    element: <ProfilePageHeader />,
  },
  {
    path: demoPagesMenu.sales.subMenu.transactions.path,
    element: <ProfilePageHeader />,
  },
  {
    path: demoPagesMenu.chat.subMenu.withListChat.path,
    element: <ProfilePageHeader />,
  },
  {
    path: demoPagesMenu.chat.subMenu.onlyListChat.path,
    element: <ProfilePageHeader />,
  },
  {
    path: demoPagesMenu.gridPages.subMenu.gridBoxed.path,
    element: <ProductsHeader />,
  },
  {
    path: demoPagesMenu.gridPages.subMenu.gridFluid.path,
    element: <ProductsHeader />,
  },
  {
    path: demoPagesMenu.listPages.subMenu.listBoxed.path,
    element: <ProductListHeader />,
  },
  {
    path: demoPagesMenu.sales.subMenu.salesList.path,
    element: <ProductListHeader />,
  },
  {
    path: demoPagesMenu.sales.subMenu.productsGrid.path,
    element: <ProductListHeader />,
  },
  {
    path: `${demoPagesMenu.sales.subMenu.productID.path}/:id`,
    element: <ProductListHeader />,
  },
  {
    path: `${componentPagesMenu.components.path}/*`,
    element: <ComponentsHeader />,
  },
  {
    path: `${componentPagesMenu.forms.path}/*`,
    element: <FormHeader />,
  },
  {
    path: `${componentPagesMenu.content.path}/*`,
    element: <ContentHeader />,
  },
  {
    path: `${componentPagesMenu.icons.path}/*`,
    element: <IconHeader />,
  },
  {
    path: '*',
    element: <ProfilePageHeader />,//<DefaultHeader />,
  },
];

export default headers;
