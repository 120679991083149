import React, { SVGProps } from 'react';

const SvgSettingsOverscan = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg viewBox='0 0 24 24' fill='currentColor' className='svg-icon' {...props}>
            <path d='M0 0h24v24H0V0z' fill='none' />
            <path
                d='M3 19.01h18V4.99H3v14.02zM18 10l2.5 2.01L18 14v-4zm-5.99-4.5L14 8h-4l2.01-2.5zM14 16l-1.99 2.5L10 16h4zm-8-6v4l-2.5-1.99L6 10z'
                opacity={0.3}
            />
            <path d='M14 16h-4l2.01 2.5zm4-6v4l2.5-1.99zm3-7H3c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16.01H3V4.99h18v14.02zM6 10l-2.5 2.01L6 14zm6.01-4.5L10 8h4z' />
        </svg>
    );
};

export default SvgSettingsOverscan;
