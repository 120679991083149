import React, { SVGProps } from 'react';

const SvgNoTransfer = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg viewBox='0 0 24 24' fill='currentColor' className='svg-icon' {...props}>
            <path fill='none' d='M0 0h24v24H0z' />
            <path
                d='M14.83 12H18v3.17L14.83 12zm-5.66 0l5 5H6.63l-.29-.27A.968.968 0 016 16v-4h3.17zm.83 2.5c0-.83-.67-1.5-1.5-1.5S7 13.67 7 14.5 7.67 16 8.5 16s1.5-.67 1.5-1.5zM7.82 4.99h9.83C17.11 4.46 15.69 4 12 4c-2.4 0-3.83.2-4.69.48l.51.51z'
                opacity={0.3}
            />
            <path d='M8.5 13c.83 0 1.5.67 1.5 1.5S9.33 16 8.5 16 7 15.33 7 14.5 7.67 13 8.5 13zM7.31 4.48C8.17 4.2 9.6 4 12 4c3.69 0 5.11.46 5.66.99H7.82l2 2H18V10h-5.17l2 2H18v3.17l1.81 1.81c.11-.31.19-.63.19-.98V6c0-3.5-3.58-4-8-4-2.52 0-4.76.16-6.22.95l1.53 1.53zm12.47 18.13l-1.64-1.64c-.05.01-.09.03-.14.03h-1c-.55 0-1-.45-1-1v-1H8v1c0 .55-.45 1-1 1H6c-.55 0-1-.45-1-1v-1.78c-.61-.55-1-1.34-1-2.22V6.83L1.39 4.22 2.8 2.81l18.38 18.38-1.4 1.42zM6 8.83V10h1.17L6 8.83zM14.17 17l-5-5H6v4c0 .37.21.62.34.73l.29.27h7.54z' />
        </svg>
    );
};

export default SvgNoTransfer;
