let LoginDetails = {
  Google: { clientId: ''},
  Facebook: { clientId: ''},
  Linkedin: { clientId: '', redirectUri: '', state:''}
};
	
if (process.env.NODE_ENV === 'development') {
  LoginDetails = {
    Google: { clientId: '446224888617-h79hjnquo6g7mjbpkm92augcfveif67d.apps.googleusercontent.com'},
    Facebook: { clientId: '1603650730127748'},
    Linkedin: { clientId: '78j90r371mh4kq', redirectUri: 'http://localhost:3000/login', state:'0JwPtzi9erDfUWauCsqB'}
  };
} else {
  // production
  LoginDetails = {
    Google: { clientId: '446224888617-h79hjnquo6g7mjbpkm92augcfveif67d.apps.googleusercontent.com'},
    Facebook: { clientId: '1603650730127748'},
    Linkedin: { clientId: '78j90r371mh4kq', redirectUri: 'https://app.ralu.co/login', state:'0JwPtzi9erDfUWauCsqB'}
  };
}
const AUTH_STATUS = LoginDetails;
export default AUTH_STATUS;