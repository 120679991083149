import React, { SVGProps } from 'react';

const SvgModeEdit = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg viewBox='0 0 24 24' fill='currentColor' className='svg-icon' {...props}>
            <path fill='none' d='M0 0h24v24H0z' />
            <path opacity={0.3} d='M5 18.08V19h.92l9.06-9.06-.92-.92z' />
            <path d='M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM5.92 19H5v-.92l9.06-9.06.92.92L5.92 19zM20.71 5.63l-2.34-2.34a.996.996 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83a.996.996 0 000-1.41z' />
        </svg>
    );
};

export default SvgModeEdit;
