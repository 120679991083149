import React, { SVGProps } from 'react';

const SvgSignalCellularConnectedNoInternet1Bar = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg viewBox='0 0 24 24' fill='currentColor' className='svg-icon' {...props}>
            <path d='M0 0h24v24H0V0z' fill='none' />
            <path d='M22 8V2L2 22h16V8h4z' fillOpacity={0.3} />
            <path d='M20 10v8h2v-8h-2zm-8 12V12L2 22h10zm8 0h2v-2h-2v2z' />
        </svg>
    );
};

export default SvgSignalCellularConnectedNoInternet1Bar;
