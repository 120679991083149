import React, { SVGProps } from 'react';

const SvgCameraRoll = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg viewBox='0 0 24 24' fill='currentColor' className='svg-icon' {...props}>
            <path d='M0 0h24v24H0V0z' fill='none' />
            <path
                d='M12 5H9V3H7v2H4v15h8v-2h8V7h-8V5zm-1 12H9v-2h2v2zm0-7H9V8h2v2zm6-2h2v2h-2V8zm0 7h2v2h-2v-2zm-4-7h2v2h-2V8zm0 7h2v2h-2v-2z'
                opacity={0.3}
            />
            <path d='M14 5c0-1.1-.9-2-2-2h-1V2c0-.55-.45-1-1-1H6c-.55 0-1 .45-1 1v1H4c-1.1 0-2 .9-2 2v15c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2h8V5h-8zm6 13h-8v2H4V5h3V3h2v2h3v2h8v11zM9 15h2v2H9zm0-7h2v2H9zm4 7h2v2h-2zm0-7h2v2h-2zm4 7h2v2h-2zm0-7h2v2h-2z' />
        </svg>
    );
};

export default SvgCameraRoll;
