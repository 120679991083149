import React, { SVGProps } from 'react';

const SvgStoreMallDirectory = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg viewBox='0 0 24 24' fill='currentColor' className='svg-icon' {...props}>
            <path d='M0 0h24v24H0V0z' fill='none' />
            <path d='M5.64 9l-.6 3h13.92l-.6-3z' opacity={0.3} />
            <path d='M4 7l-1 5v2h1v6h10v-6h4v6h2v-6h1v-2l-1-5H4zm8 11H6v-4h6v4zm-6.96-6l.6-3h12.72l.6 3H5.04zM4 4h16v2H4z' />
        </svg>
    );
};

export default SvgStoreMallDirectory;
