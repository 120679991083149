import React, { SVGProps } from 'react';

const SvgEditAttributes = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg viewBox='0 0 24 24' fill='currentColor' className='svg-icon' {...props}>
            <path d='M0 0h24v24H0V0z' fill='none' />
            <path
                d='M17.63 9H6.37C5.09 9 4 10.37 4 12s1.09 3 2.37 3h11.26c1.28 0 2.37-1.37 2.37-3s-1.09-3-2.37-3zM7.24 14.46l-2.57-2.57.7-.7 1.87 1.87 3.52-3.52.7.7-4.22 4.22z'
                opacity={0.3}
            />
            <path d='M17.63 7H6.37C3.96 7 2 9.24 2 12s1.96 5 4.37 5h11.26c2.41 0 4.37-2.24 4.37-5s-1.96-5-4.37-5zm0 8H6.37C5.09 15 4 13.63 4 12s1.09-3 2.37-3h11.26C18.91 9 20 10.37 20 12s-1.09 3-2.37 3zM7.24 13.06l-1.87-1.87-.7.7 2.57 2.57 4.22-4.22-.7-.7z' />
        </svg>
    );
};

export default SvgEditAttributes;
