import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { demoPagesMenu } from '../../menu';
import { DropdownItem, DropdownMenu } from '../../components/bootstrap/Dropdown';
import Button from '../../components/bootstrap/Button';
import useDarkMode from '../../hooks/useDarkMode';
// import useLogoutUser from '../../hooks/useLogoutUser';
import Collapse from '../../components/bootstrap/Collapse';
import { NavigationLine } from '../Navigation/Navigation';
import Icon from '../../components/icon/Icon';
import useNavigationItemHandle from '../../hooks/useNavigationItemHandle';
import ThemeContext from '../../contexts/themeContext';
import AuthContext from '../../contexts/authContext';
import { deleteDataAPI } from '../../helpers/httpUtils';
// import USERS from '../../common/data/userDummyData';

const User = () => {
  const { userData, setUser } = useContext(AuthContext);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { asideStatus, setAsideStatus } = useContext(ThemeContext);
  const  currentUserId = localStorage.getItem('authUserId');
  const  currentUserFsNm = localStorage.getItem('authUserFsNm');
  const  currentUserLsNm = localStorage.getItem('authUserLsNm');
  const  currentUserPic = localStorage.getItem('authUserPic');
  const profilePic = currentUserPic !== null ? currentUserPic : undefined;

  const navigate = useNavigate();
  const handleItem = useNavigationItemHandle();
  const { darkModeStatus, setDarkModeStatus } = useDarkMode();

  const [collapseStatus, setCollapseStatus] = useState<boolean>(false);

  const logoutUser = async() => {
    const res =  await deleteDataAPI('/auth/logout');
    if(res.data.statusCode === 200){
      if (setUser) {
        setUser('');
      }
      if(setAsideStatus) {
        setAsideStatus( false );
      }
      // if()
      await localStorage.removeItem('currentUserDetails');
      if(localStorage.getItem('isAdmin')){
        await navigate(`../${demoPagesMenu.adminLogin.path}`);
      } else {
        await navigate(`../${demoPagesMenu.login.path}`);
      }
      // await navigate(`../${demoPagesMenu.login.path}`);
    }
  };

  return (
    <>
      <div
        className={classNames('user', { open: collapseStatus })}
        role='presentation'
        onClick={() => setCollapseStatus(!collapseStatus)}>
        <div className='user-avatar ntIm_me-2'>
          <img
            //srcSet={userData?.srcSet}
            //src={userData?.src}
            src={profilePic}
            alt='Avatar'
            width={128}
            height={128}
            referrerPolicy="no-referrer"
          />
        </div>
        <div className='user-info'>
          <div className='user-name d-flex align-items-center'>
            {/* {`${userData?.name} ${userData?.surname}`} */}
            {currentUserFsNm} {currentUserLsNm}
            <Icon icon='Verified' className='ms-1' color='info' />
          </div>
          <div className='user-sub-title'>{userData?.position}</div>
        </div>
      </div>
      <DropdownMenu>
        <DropdownItem>
          <Button
            icon='AccountBox'
            onClick={() =>
              navigate(
                `../${demoPagesMenu.friend.subMenu.employeeID.path}/${userData?.id}`,
              )
            }>
						Profile
          </Button>
        </DropdownItem>
        <DropdownItem>
          <Button
            icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
            onClick={() => setDarkModeStatus(!darkModeStatus)}
            aria-label='Toggle fullscreen'>
            {darkModeStatus ? 'Dark Mode' : 'Light Mode'}
          </Button>
        </DropdownItem>
      </DropdownMenu>

      <Collapse isOpen={collapseStatus} className='user-menu'>
        <nav aria-label='aside-bottom-user-menu'>
          <div className='navigation'>
            <div
              role='presentation'
              className='navigation-item cursor-pointer'
              onClick={() =>
                navigate(
                  `../${demoPagesMenu.friend.subMenu.employeeID.path}/${currentUserId}`,
                  // @ts-ignore
                  handleItem(),
                )
              }>
              <span className='navigation-link navigation-link-pill'>
                <span className='navigation-link-info'>
                  <Icon icon='AccountBox' className='navigation-icon' />
                  <span className='navigation-text'>
									    {'Profile'}{/* {t('menu:Profile') as ReactNode} */}
                  </span>
                </span>
              </span>
            </div>
            <div
              role='presentation'
              className='navigation-item cursor-pointer'
              onClick={() =>
                navigate(
                  `../${demoPagesMenu.sessionList.path}`,
                  // @ts-ignore
                  handleItem(),
                )
              }>
              <span className='navigation-link navigation-link-pill'>
                <span className='navigation-link-info'>
                  <Icon icon='AccessTimeFilled' className='navigation-icon' />
                  <span className='navigation-text'>
									    {'Sessions'}{/* {t('menu:Profile') as ReactNode} */}
                  </span>
                </span>
              </span>
            </div>
            <div
              role='presentation'
              className='navigation-item cursor-pointer'
              onClick={() => {
                setDarkModeStatus(!darkModeStatus);
                handleItem();
              }}>
              <span className='navigation-link navigation-link-pill'>
                <span className='navigation-link-info'>
                  <Icon
                    icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
                    color={darkModeStatus ? 'info' : 'warning'}
                    className='navigation-icon'
                  />
                  <span className='navigation-text'>
                    {darkModeStatus
                    // ? (t('menu:DarkMode') as ReactNode)
                    // : (t('menu:LightMode') as ReactNode)}
                      ? ('DarkMode' )
                      : ('LightMode' )}
                  </span>
                </span>
              </span>
            </div>
          </div>
        </nav>
        <NavigationLine />
        <nav aria-label='aside-bottom-user-menu-2'>
          <div className='navigation'>
            <div
              role='presentation'
              className='navigation-item cursor-pointer'
              // onClick={useLogoutUser()}>
              onClick={() => logoutUser()}>
              <span className='navigation-link navigation-link-pill'>
                <span className='navigation-link-info'>
                  <Icon icon='Logout' className='navigation-icon' />
                  <span className='navigation-text'>
									    {'Logout' }{/* {t('menu:Logout') as ReactNode} */}
                  </span>
                </span>
              </span>
            </div>
          </div>
        </nav>
      </Collapse>
    </>
  );
};

export default User;
/*
1c = 50 
10 = 500
100 = 5000
1000 = 50000
10k = 5l

1l - 10
1c - 1000
10cr - 10k 
100 c - 1l
1000c - 10l
6000c - 60l
*/