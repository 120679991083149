import React, { SVGProps } from 'react';

const SvgEditLocation = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg viewBox='0 0 24 24' fill='currentColor' className='svg-icon' {...props}>
            <path fill='none' d='M0 0h24v24H0z' />
            <path
                d='M14.11 14H8V7.91l.59-.59L11.91 4C8.61 4.05 6 6.6 6 10.2c0 2.34 1.95 5.44 6 9.14 4.05-3.7 6-6.79 6-9.14v-.08l-3.3 3.3-.59.58z'
                opacity={0.3}
            />
            <path d='M18.17 4.91L17.1 3.84l-5.55 5.55v1.08h1.08l5.54-5.56zM16 2.74l1.29-1.29c.58-.59 1.52-.59 2.11-.01l.01.01 1.15 1.15c.59.59.59 1.54 0 2.12l-.68.68-.02.02-.58.58-6 6H10V8.74l6-6zm-2.28-.55l-.55.55-1.27 1.27c-3.3.05-5.9 2.6-5.9 6.2 0 2.34 1.95 5.44 6 9.14 4.05-3.7 6-6.79 6-9.14v-.1l1.8-1.8c.13.6.2 1.24.2 1.9 0 3.32-2.67 7.25-8 11.8-5.33-4.55-8-8.48-8-11.8 0-4.98 3.8-8.2 8-8.2.58 0 1.16.06 1.72.18z' />
            <path opacity={0.3} d='M18.17 4.91L17.1 3.84l-5.55 5.55v1.08h1.08z' />
        </svg>
    );
};

export default SvgEditLocation;
