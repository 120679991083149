import React, { FC, useState, useContext, useEffect, useRef, UIEvent} from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes, { number, string } from 'prop-types';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import Button from '../../../components/bootstrap/Button';
import useDarkMode from '../../../hooks/useDarkMode';
import AuthContext from '../../../contexts/authContext';
// eslint-disable-next-line
import USERS, { IUserProps } from '../../../common/data/userDummyData';
import { demoPagesMenu2,demoPagesMenu } from '../../../menu';
import { useGoogleLogin } from '@react-oauth/google';//GoogleLogin,
import { postDataAPI, postDataAPI2 ,deleteDataAPI } from '../../../helpers/httpUtils';
import showNotification from '../../../components/extras/showNotification';
import Icon from '../../../components/icon/Icon';
import AuthLogin from '../../../common/Auth/auth';
import Spinner from '../../../components/bootstrap/Spinner';
// import Calendar from 'react-calendar'; 
import { Calendar } from "./components/index";
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
//import { FacebookProvider, LoginButton } from 'react-facebook';
//import FacebookLogin from 'react-facebook-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { Input } from '../../../components/icon/material-icons';
import validate from '../profile/helper/editPagesValidate';
import { timeObject } from '../../../common/data/interfaces';

const LoginHeader = () => {
  return (
    <>
      <div className='col-12 px-0'>
        <div className='text-center h1 fw-bold mb-2'>Welcome</div>
        <div className='text-center h4 clrGrey text-capitalize mb-0'>Sign in to continue!</div>
      </div>
    </>
  );
};

interface ILoginProps {
  isSignUp?: boolean;
}

const Login: FC<ILoginProps> = ({ isSignUp }) => {

  const { setUser } = useContext(AuthContext); //, setAuthPic, setAuthEmail, setAuthId
  const [locTitle, setLocTitle] = useState<string>('');
  const [locBgImg, setLocBgImg] = useState<string>('https://t3.ftcdn.net/jpg/05/55/82/46/360_F_555824600_OvpqblejjhjExcDDulsWkY3RxDrAr2hF.jpg');
  const [isLoading, setIsLoading] = useState(true);
  const [isLocCalled, setIsLocCalled] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { darkModeStatus, setDarkModeStatus } = useDarkMode();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [singUpStatus, setSingUpStatus] = useState<boolean>(!!isSignUp);
  const [enableSelectTrip, setEnableSelectTrip] = useState<boolean>(true);
  const [ timeZone, setTimeZone] = useState<string>();
  const [enableTripCard, setEnableTripCard] = useState<boolean>(true);
  // const[fromdate, setFromdate]= useState<number>(new Date().getTime());
  // const[todate, setTodate]= useState<number>(new Date().getTime());
  // const[validdate, setValiddate]= useState<number>(new Date().getTime());
  // const[options, setOptions]= useState<number>(1);

  const handleLinkedInLogin = () => {
    const clientId = AuthLogin.Linkedin.clientId;
    const redirectUri = AuthLogin.Linkedin.redirectUri;
    const state = AuthLogin.Linkedin.state;

    const url = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&state=${state}&scope=r_liteprofile r_emailaddress`;

    window.location.href = url;
  };

  const handleAuthorizationCallback = async () => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');
    if (code) {
      showNotification(
        <span className='d-flex align-items-center'>
          <Icon icon='Beenhere' size='lg' className='me-1' />
          <span>Logging in</span>
        </span>,
        '',
        'success'
      );
      const res = await postDataAPI2('/auth/linkedin-login', { 'code': code });
      const users = await res;
      if (users.statusCode === 200) {
        const userDetails = users.details;
        if (setUser) {
          USERS.CURRENTUSER.userId = userDetails.userID;
          USERS.CURRENTUSER.email = userDetails.email;
          USERS.CURRENTUSER.username = userDetails.firstName;
          USERS.CURRENTUSER.src = userDetails.picture;
          USERS.CURRENTUSER.srcSet = userDetails.picture;
          USERS.CURRENTUSER.surname = userDetails.lastName;
          USERS.CURRENTUSER.name = userDetails.firstName;
          setUser(USERS.CURRENTUSER.username);
          localStorage.setItem('authUserFsNm', userDetails.firstName);
          localStorage.setItem('authUserLsNm', userDetails.lastName);
          localStorage.setItem('authUserId', userDetails.userID);
          localStorage.setItem('authUserEmail', userDetails.email);
          localStorage.setItem('authUserPic', userDetails.picture);
        }
        if (userDetails.oldUser) {
          showNotification(
            <span className='d-flex align-items-center'>
              <Icon icon='Beenhere' size='lg' className='me-1' />
              <span>Welcome back</span>
            </span>,
            'Your old account is retrived.',
            'success'
          );
        }
        goToFriendsList();
      } else {
        console.log('google login failed');
      }
    }
  };

  useEffect(() => {
    setDarkModeStatus(false);
    localStorage.removeItem('isAdmin');
    handleAuthorizationCallback();
  }, []);

  useEffect(() => {
    //const storedLocationId = sessionStorage.getItem('locationId');
    const storedLocationId = localStorage.getItem('locationId');
    if (storedLocationId && storedLocationId !== 'nolocation' && storedLocationId.trim() !== '') {
      getLocationDetails(storedLocationId);
    } else {
      setIsLocCalled(true);
    }
  }, []);

  const getLocationDetails = async (storedLocationId: string) => {
    const locRes = await postDataAPI2('/location/location-detail-login', { 'locId': storedLocationId });
    setIsLocCalled(true);
    if (locRes.statusCode === 200) {
      setLocTitle(locRes.details.title);
      setTimeZone(locRes.details.tmZn);
      if (locRes.details.bgImg) {
        setLocBgImg(locRes.details.bgImg);
      
      }
      if(locRes.details.tmZn){
        setTimeZone(locRes.details.tmZn);
      }
      if(locRes.details.isDate)
      {
        setEnableSelectTrip(locRes.details.isDate)
        
      }
      else{
        setEnableSelectTrip(false);
      }
  
      // setIsLocCalled(true);
    } else {
      // setIsLocCalled(true);
    }
  };

  const handleOnClick = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      const storedLocationId = localStorage.getItem('locationId');
      const res = postDataAPI('/auth/google-login', { 'code': codeResponse.access_token, 'locId':  storedLocationId !== '' ? storedLocationId : '' });
      const users = await res;
      
      if (users.data.statusCode === 200) {
        // console.log('google login:',users);
        const userDetails = users.data.details;
        if (setUser) {
          USERS.CURRENTUSER.userId = userDetails.userID;
          USERS.CURRENTUSER.email = userDetails.email;
          USERS.CURRENTUSER.username = userDetails.firstName;
          USERS.CURRENTUSER.src = userDetails.picture;
          USERS.CURRENTUSER.srcSet = userDetails.picture;
          USERS.CURRENTUSER.surname = userDetails.lastName;
          USERS.CURRENTUSER.name = userDetails.firstName;
          setUser(USERS.CURRENTUSER.username);
          localStorage.setItem('authUserFsNm', userDetails.firstName);
          localStorage.setItem('authUserLsNm', userDetails.lastName);
          localStorage.setItem('authUserId', userDetails.userID);
          localStorage.setItem('authUserEmail', userDetails.email);
          localStorage.setItem('authUserPic', userDetails.picture);
        }
        if (userDetails.oldUser) {
          showNotification(
            <span className='d-flex align-items-center'>
              <Icon icon='Beenhere' size='lg' className='me-1' />
              <span>Welcome back</span>
            </span>,
            'Your old account is retrived.',
            'success'
          );
        }

        let locDetails = users.data.locDetails;
        let dateDetails = users.data.isDatePresent;
        if(dateDetails){
          if(dateDetails.isDate){
            let timeNow = new Date();
            if(locDetails && locDetails.users.length > 0){
              if(locDetails.users[0].valid){
                if(timeNow > new Date(locDetails.users[0].valid)){
                  setEnableTripCard(false);
                } else {
                  goToFriendsList();
                }
              } else {
                setEnableTripCard(false);
              }
              
            } else {
              setEnableTripCard(false);
            }
          } else {
            goToFriendsList();
          }
        } else {
          goToFriendsList();
        }
       
        // if(enableSelectTrip == true){
        //   setEnableTripCard(false);
        // }else{
        //   goToFriendsList();
        // }
      
       } 

      else {
      }
    }
  });

  const handleSuccess = async (response: any) => {
    //console.log('response:',response);
    const fbResult = await postDataAPI2('/auth/facebook-login', {
      // "userId": response.authResponse.userID,
      // "accessToken": response.authResponse.accessToken
      'userId': response.userID,
      'accessToken': response.accessToken
    });
    //console.log('result:',fbResult);
    if (fbResult.statusCode === 200) {
      const userDetails = fbResult.details;
      if (setUser) {
        USERS.CURRENTUSER.userId = userDetails.userID;
        USERS.CURRENTUSER.email = userDetails.email;
        USERS.CURRENTUSER.username = userDetails.firstName;
        USERS.CURRENTUSER.src = userDetails.picture;
        USERS.CURRENTUSER.srcSet = userDetails.picture;
        USERS.CURRENTUSER.surname = userDetails.lastName;
        USERS.CURRENTUSER.name = userDetails.firstName;
        setUser(USERS.CURRENTUSER.username);
        localStorage.setItem('authUserFsNm', userDetails.firstName);
        localStorage.setItem('authUserLsNm', userDetails.lastName);
        localStorage.setItem('authUserId', userDetails.userID);
        localStorage.setItem('authUserEmail', userDetails.email);
        localStorage.setItem('authUserPic', userDetails.picture);
      }
      if (userDetails.oldUser) {
        showNotification(
          <span className='d-flex align-items-center'>
            <Icon icon='Beenhere' size='lg' className='me-1' />
            <span>Welcome back</span>
          </span>,
          'Your old account is retrived.',
          'success'
        );
      }
      goToFriendsList();
    } else {
      showNotification(
        <span className='d-flex align-items-center'>
          <Icon icon='Beenhere' size='lg' className='me-1' />
          <span>Sorry,Unable to login.</span>
        </span>,
        'warning'
      );
    }
  };

  const navigate = useNavigate();

  const goToFriendsList = () => {
    const storedLocationId = localStorage.getItem('locationId');
    if (storedLocationId && storedLocationId !== 'nolocation' && storedLocationId.trim() !== '') {
      navigate(`../${demoPagesMenu2.friends.path}/${storedLocationId}`);
    } else {
      navigate(`../${demoPagesMenu2.friends.path}/${'nolocation'}`);
    }
    //navigate(`../${demoPagesMenu2.friends.path}/${'nolocation'}`),
  };

  const handleImageLoad = () => {
    // Image has finished loading
    setIsLoading(false);
  };

  const months = [
    'Jan', 'Feb', 'Mar', 'Apr',
    'may', 'Jun', 'Jul', 'Aug',
    'Sep', 'Oct', 'Nov', 'Dec'
  ];

  const [selectedMonthIndex, setSelectedMonthIndex] = useState<number>(new Date().getMonth());
  const [selectedDate, setSelectedDate] = useState<number>(new Date().getDate());
  const [selectedMonthIndex1, setSelectedMonthIndex1] = useState<number>(new Date().getMonth());
  const [selectedDate1, setSelectedDate1] = useState<number>(1);
  const [fromYear, setFromYear] = useState<number>(new Date().getFullYear());
  const [toYear, setToYear] = useState<number>(new Date().getFullYear());
  const [default1, setDefault1] = useState(false);
  const [default2, setDefault2] = useState(false);
  const [default3, setDefault3] = useState(false);
  const [default4, setDefault4] = useState(false);
  const [default5, setDefault5] = useState(false);
  const [default6, setDefault6] = useState(false);
  
  const [isHighlighted, setIsHighlighted] = useState(false);
  const [isHighlighted1, setIsHighlighted1] = useState(false);
  const [isHighlighted2, setIsHighlighted2] = useState(false);

  const toggleClassName = () => {
    setIsHighlighted(true);
    setIsHighlighted1(false)
    setIsHighlighted2(false)

  };

  const cardClassName = `col border mainCard rounded bgTrnspClrWht ${isHighlighted ? 'border-3 border-white' : 'border'}`;
  const cardClassName1 = `text-center px-5 fs-5 fw-medium py-3 bgHrbtn txt14-xs vwBlrNtCk clrWht ${isHighlighted ? 'bgBlack opacity1Pt0 im_vwClick' : 'bgBlack opacity1Pt0'}`;

  const toggleClassName1 = () => {

    setIsHighlighted1(true);
    setIsHighlighted(false);
    setIsHighlighted2(false);
    resetDatesInOption();
  };

  const buttonClassName = `fs-5 w-100 h5 fw-medium py-3 txt13-xs text-center clrWht border mb-4 ${isHighlighted1 ? 'border-3 border-white' : 'border'}`;
  const buttonClassName1 = `text-center fs-5 fw-medium px-5 py-3 bgHrbtn txt14-xs vwBlrNtCk clrWht ${isHighlighted1 ? 'bgBlack opacity1Pt0 im_vwClick' : 'bgBlack opacity1Pt0'}`;

  const toggleClassName2 = () => {

    setIsHighlighted2(true)
    setIsHighlighted1(false)
    setIsHighlighted(false)
    resetDatesInOption();
  }

  const resetDatesInOption = () => {
    setDefault1(false);
    setDefault2(false);
    setDefault3(false);
    setDefault4(false);
    setDefault5(false);
    setDefault6(false);
    setSelectedDate(new Date().getDate());
    setSelectedDate1(1);
    setSelectedMonthIndex(new Date().getMonth());
    setSelectedMonthIndex1(new Date().getMonth());
    setFromYear(new Date().getFullYear());
    setToYear(new Date().getFullYear());
  }

  const lastbtn = `fs-5 w-100 h5 fw-medium py-3 txt13-xs text-center clrWht border mb-4 ${isHighlighted2 ? 'border-3 border-white' : 'border'}`;
  const lastbtn1 = `text-center fs-5 fw-medium  py-3 px-5 txt14-xs vwBlrNtCk clrWht bgHrbtn ${isHighlighted2 ? 'bgBlack opacity1Pt0 im_vwClick' : 'bgBlack opacity1Pt0'}`;
  const defaultbtn = `text-center fs-5 fw-medium bgBlack opacity1Pt0 py-3 px-5 txt14-xs vwBlrNtCk clrWht` ;


  const dateValidation = () => {
    if (!isHighlighted && !isHighlighted1 && !isHighlighted2) {
     
      callNotification("Please click the plan you want.", '', 'Warning', 'danger');
    }

    else if (isHighlighted) {
      let fromDateConcat = months[selectedMonthIndex] + '-' + selectedDate + '-' + fromYear ;//changes
      let toDateCount = months[selectedMonthIndex1] + '-' + selectedDate1 + '-' + toYear ;//changes
      let fromDateToValidate = new Date(fromDateConcat.toString());
      let toDateToValidate = new Date(toDateCount.toString());
      if(toDateToValidate < new Date()){
        callNotification("The 'To' date must be later than the today date.", '', 'Warning', 'danger');
      } else if(toDateToValidate >= fromDateToValidate){
        callNotification("welcome", '', 'success', 'success');
        callScheduleDatesApi();
      }
      else {
        callNotification("The 'To' date must be later than the 'From' date.", '', 'Warning', 'danger');
      }
    }
    else{
      callNotification("welcome", '', 'success', 'success');
      callScheduleDatesApi();
      // goToFriendsList();
    }

  }
  const callNotification = async (
    title: string,
    msg: string,
    icon?: string,
    type?: string
  ) => {
    showNotification(
      <span className="d-flex align-items-center">
        <Icon icon={icon} size="lg" className="me-1" />
        <span>{title}</span>
      </span>,
      msg,
      type
    );
  };

  const callScheduleDatesApi = async() => {
    let fromDate = new Date(`${months[selectedMonthIndex]}-${selectedDate}-${fromYear}`).toLocaleString("en-US", { timeZone });
    let fromDateString = new Date(new Date(fromDate).getTime());
    
    //option 1
   if(isHighlighted){
     const toDate = new Date(`${months[selectedMonthIndex1]}-${selectedDate1}-${toYear}`).toLocaleString("en-US", { timeZone });
     let toDateString = new Date(new Date(toDate).getTime());
     const option = 1;
     const validDate = new Date(toDateString);
     validDate.setDate(toDateString.getDate() + 1);
     validDate.setHours(23);
     validDate.setMinutes(58);

     const scheduleResponse = await postDataAPI2('/location/addSchedule-dates', {
      'locId': localStorage.getItem('locationId'),
      'from': fromDateString.getTime(),
      'to': toDateString.getTime(),
      'valid': validDate.getTime(),
      'option' :option,
      'userId' :localStorage.getItem('authUserId'),
    });
     if(scheduleResponse.statusCode === 200){
      goToFriendsList();
     }
     else{
      console.log("data not accessed")
     }
  
     }
     else {
      const toDateString = new Date();
      toDateString.setDate(new Date(fromDateString).getDate());
      if(isHighlighted1)  {
      const option = 2;
      const validDate = new Date(toDateString);
      validDate.setHours(toDateString.getHours() + 24)
      // console.log(fromDateString,toDateString,validDate,"option2")
      const scheduleResponse = await postDataAPI2('/location/addSchedule-dates', {
        'locId': localStorage.getItem('locationId'),
        'from': fromDateString.getTime(),
        'to': toDateString.getTime(),
        'valid': validDate.getTime(),
        'option' :option,
        'userId' :localStorage.getItem('authUserId'),
      });
      if(scheduleResponse.statusCode === 200){
        goToFriendsList();
       }
       else{
        // console.log("data not accessed")
       }
    }
      
       else {
          const option = 3;
          const validDate = new Date(toDateString);
          validDate.setDate(toDateString.getDate() + 7);
          // console.log(fromDateString,toDateString,validDate,"option3")
          const scheduleResponse = await postDataAPI2('/location/addSchedule-dates', {
            'locId': localStorage.getItem('locationId'),
            'from': fromDateString.getTime(),
            'to': toDateString.getTime(),
            'valid': validDate.getTime(),
            'option' :option,
            'userId' :localStorage.getItem('authUserId'),
          });
          if(scheduleResponse.statusCode === 200){
            goToFriendsList();
           }
           else{
            // console.log("data not accessed")
           }
            }
  }}

  //  const [selectedDates, setSelectedDates] = useState<(Date | null)[]>([null, null, null]);


  const [dateFromCalendar, setDateFromCalendar] = useState<any>(new Date());
  const [selectRange, setSelectRange] = useState<boolean>(true);
  const [ lastPickedCalender, setLastPickedCalender] = useState<string>('');
  const [ selectedFromDate, setSelectedFromDate ] = useState<any>(new Date());
  const [ selectedFromTo, setSelectedFromTo ] = useState<any>(new Date());
  const [ isCalendarOpen, setIsCalendarOpen ] = useState(false);
  // const calendarRef = useRef(null);
  const calendarRef = useRef<HTMLDivElement | null>(null);


  const toggleCalendar = (type: string) => {
    if(type === 'From'){
      setDateFromCalendar(selectedFromDate);
    } else {
      setDateFromCalendar(selectedFromTo);
    }
    setLastPickedCalender(type);
    setIsCalendarOpen((prev) => !prev);
  };


  const closeCalenderByOutsideClick = () => {
    setIsCalendarOpen(false);
  }


  useEffect(()=>{

    if(dateFromCalendar.length > 1){
        // console.log('two date 1:',dateFromCalendar);
        let toDateFromArr = dateFromCalendar[0];
        let fromDateFromArr = dateFromCalendar[1];
        setSelectedFromDate(toDateFromArr);
        setSelectedMonthIndex(toDateFromArr.getMonth());
        setDefault1(true);
        setSelectedDate(toDateFromArr.getDate());
        setDefault2(true);
        setFromYear(toDateFromArr.getFullYear());
        setDefault5(true);
        setSelectedFromTo(fromDateFromArr)
        setSelectedMonthIndex1(fromDateFromArr.getMonth());
        setDefault3(true);
        setSelectedDate1(fromDateFromArr.getDate());
        setDefault4(true);
        setToYear(fromDateFromArr.getFullYear());
        setDefault6(true);
    } else {
      resetDatesInOption();
    }
  },[dateFromCalendar])

const signOutUser = async() => {
  const res =  await deleteDataAPI('/auth/logout');
  if(res.data.statusCode === 200){
    if (setUser) {
      setUser('');
      
    }
    await localStorage.removeItem('currentUserDetails');
    if(localStorage.getItem('isAdmin')){
      await navigate(`../${demoPagesMenu.adminLogin.path}`);
    } else {
      await navigate(`../${demoPagesMenu.login.path}`);
     
    }
    // await navigate(`../${demoPagesMenu.login.path}`);
    setEnableTripCard(true);
  }
};


  return (
    <PageWrapper
      isProtected={false}
      title={singUpStatus ? 'Sign Up' : 'Login'}
      className={classNames({ 'bg-dark': !singUpStatus, 'bg-light': singUpStatus }, 'px-0 position-relative')}>
      {enableTripCard?
      <Page className='p-0 lgnCdPnl'>
        <div className='row m-0 h-100 align-items-end align-items-md-center justify-content-center'>
          {isLoading ?
            <div className='w-100 mt-5 d-flex justify-content-center align-items-center'>
              <Spinner className='' color={'info'}>
                Loading...
              </Spinner>
            </div> :
             
            <div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container px-3'>
              <Card className='shadow-3d-dark bgTrnspClrWht brd30 mt-5 mb-4 mb-md-5 tpLtRtBf0 tpLtRtAf0' data-tour='login-page'>
                <CardBody className='vt-dWp-center'>
                  <div className='col-12 px-0 im_pb-2'>
                    {locTitle.trim() !== '' ? 
                      <div className='col-12 px-0'>
                        <div className='text-center h1 fw-bold mb-2'>{locTitle}</div>
                        <div className='text-center h4 clrGrey text-capitalize mb-0'>Meet People. Make Friends.</div>
                      </div> 
                      : 
                      <LoginHeader />
                    }
                  </div>
                  <div className='col-12 px-0'>
                    <div className='lgnCdPnlHt'>
                      <div className='col-12 px-0 hr-d-flex-center py-1'>
                        <div className='col-auto px-0 wt250'>
                          <Button
                            isOutline
                            color={'light'}
                            className={
                              classNames('w-100 px-5 py-3 text-start clrWht bgHrGreyWht border-light mb-3'
                              )}
                            icon='CustomGoogle'
                            onClick={() => handleOnClick()}>
                          Continue with Google
                          </Button>
                          <FacebookLogin
                            appId={AuthLogin.Facebook.clientId}
                            autoLoad={true}
                            //   fields="name,email,picture"
                            //   scope="public_profile"
                            callback={handleSuccess}
                            icon="fa-facebook"
                            render={(renderProps) => (
                              <Button
                                isOutline
                                color={'light'}
                                className={
                                  classNames('w-100 px-5 py-3 text-start clrWht bgHrGreyWht border-light mb-3'
                                  )}
                                onClick={renderProps.onClick}
                                icon='CustomFacebook'>
                                                        Sign in with Facebook
                              </Button>
                            )} />
                          <Button
                            isOutline
                            color={'light'}
                            className={
                              classNames('w-100 px-5 py-3 text-start clrWht bgHrGreyWht border-light mb-5'
                              )}
                            icon='CustomLinkedin'
                            onClick={handleLinkedInLogin}
                          >
                                                Sign in with LinkedIn
                          </Button>

                          <div className='col-auto clrGrey'>Powered by ralu. By continuing
                            you agree to ralu’s&nbsp;
                          <a
                            href='/'
                            className={classNames('aTagLink')}>
                            Privacy policy
                          </a>
                          <span> and </span>
                          <a
                            href='/'
                            className={classNames('aTagLink')}>
                            Terms of use
                          </a>
                          </div>
                        </div>
                      </div>	
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div> 
       
          }
        </div>
      </Page>

    
      : <Page className='p-0 lgnCdPnl'>
        <div className='row m-0 h-100 align-items-end align-items-md-center justify-content-center'>
          {isLoading ?
            <div className='w-100 mt-5 d-flex justify-content-center align-items-center'>
              <Spinner className='' color={'info'}>
                Loading...
              </Spinner>
            </div> :
              <div className='col-10 col-sm-8 col-xl-4 col-lg-6 col-md-8 shadow-3d-container cardClass'>
              <Card className='shadow-3d-dark bgTrnspClrWht brd30 px-0 tpLtRtBf0 tpLtRtAf0' data-tour='login-page'>
                <CardBody className='vt-dWp-center'>
                  <div className='col-12 px-0 '>
                    <div className='text-center h5 mb-4'>Your trip to {locTitle}</div>
                  </div>
                  <Card className={cardClassName} onClick={() => toggleClassName()}>
                    <CardBody className='vt-dWp-center'>
                      <div className='col-12 h5 text-center txt13-xs mb-3'>I will be there</div>
                      <div className='col-12'>
                        <div className='row m-0 gap-2'>
                          <Card className='col minicard clrWht rounded mb-2 p-2'>
                            <CardBody className='im_p0-xs'>
                              <div className='h5 col txt13-xs text-center'>From</div>
                              <div className='col-12 px-0'>
                                  <div className="hr-d-flex-center gap-1 mt-4">
                                    <div className="col date-scroll"
                                        onClick={ ()=> toggleCalendar('From')}>
                                      {default2?selectedDate : "-"}
                                    </div>
                                    
                                    <div className="col month-scroll " onClick={ ()=> toggleCalendar('From')} >
                                      {default1?months[selectedMonthIndex] : "-"}
                                    </div>
                                    <div className="col year-scroll " onClick={ ()=> toggleCalendar('From')} >
                                      {default5 ? fromYear : "-"}
                                    </div>
                                  </div>
                                </div>
                            </CardBody>
                          </Card>
                          <Card className='col minicard clrWht  rounded mb-2 p-2'>
                            <CardBody className='im_p0-xs'>
                              <div className='h5 col txt13-xs text-center'>To</div>
                              <div className='col-12 px-0'>
                                <div className="hr-d-flex-center gap-1 mt-4">
                                  <div className="col date-scroll " onClick={ ()=> toggleCalendar('To')} >
                                    {default4 ? selectedDate1 : "-"}
                                  </div>
                                  <div className="col month-scroll " onClick={ ()=> toggleCalendar('To')}  >
                                    {default3 ? months[selectedMonthIndex1] : "-"}
                                  </div>
                                  <div className="col year-scroll " onClick={ ()=> toggleCalendar('To')} >
                                    {default6 ? toYear : "-"}
                                  </div>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </div>
                        {isCalendarOpen && (
                          <div ref={calendarRef} className="calendar hr-d-flex-center">
                            <div className="offcanvas-backdrop fade" onClick={closeCalenderByOutsideClick}></div>
                              <Calendar
                                  date={dateFromCalendar}
                                  setDate={setDateFromCalendar}
                                  selectRange={selectRange}
                                  setSelectRange={setSelectRange}
                              />
                          </div>
                        )}
                      </div>
                    </CardBody>
                  </Card>

                  <Button
                    isOutline
                    className={buttonClassName} onClick={toggleClassName1}>
                    I will not stop in {locTitle}
                  </Button>

                  <Button
                    isOutline
                    className={lastbtn} onClick={toggleClassName2}>
                    I live in {locTitle}

                  </Button>
                  <div className='col-12 text-center mb-4'>
                    <Button
                      isOutline

                      className={isHighlighted ? cardClassName1 : isHighlighted1 ? buttonClassName1 : isHighlighted2? lastbtn1:defaultbtn}

                      onClick={() => dateValidation()}>
                      Continue

                    </Button>
                  </div>


                  <div className='col-12 text-center mb-0'>
                    <Button
                      isOutline

                      className={
                        classNames('text-center clrWht fs-5 txt13-xs fw-medium'
                        )}
                        onClick={()=>signOutUser()}>
                      Sign out

                    </Button>

                  </div>

                </CardBody>
              </Card>
            </div>

          }
        </div>
      </Page>
      }

      {
        isLocCalled ?
          <div className="lgnBgImg">
            <img className="objfitCvr" alt="Login Screen" onLoad={handleImageLoad} src={locBgImg} width="100%" height="100%" />
          </div> : <></>
      }
    </PageWrapper>
  );
};

Login.propTypes = {
  isSignUp: PropTypes.bool,
};
Login.defaultProps = {
  isSignUp: false,
};

export default Login;


/*
<div className='row m-0 gap-2 doubleCard'>
                          <Card className='col minicard clrWht border rounded p-2'>
                            <CardBody className='im_p0-xs'>
                              <div className='h5 col txt14-xs text-center'>From</div>
                              <div className='col-12 px-0'>
                                  <div className="hr-d-flex-center gap-1">
                                    <div className="col date-scroll"
                                        onClick={toggleCalendar}>
                                      {default2?selectedDate : "-"}
                                    </div>
                                    
                                    <div className="col month-scroll " onWheel={handleMonthScroll} >
                                      {default1?months[selectedMonthIndex] : "-"}
                                    </div>
                                    <div className="col year-scroll " onWheel={handleYearScroll}>
     
                                      {default5 ? fromYear : "-"}
                                    </div>
                                  </div>
                                </div>
                            </CardBody>
                          </Card>
                          <Card className='col minicard clrWht border rounded p-2'>
                            <CardBody className='im_p0-xs'>
                              <div className='h5 col txt14-xs text-center'>To</div>
                              <div className='col-12 px-0'>
                                <div className="hr-d-flex-center gap-1">
                                  <div className="col date-scroll " onWheel={handleDateScroll1}  >
                                    {default4 ? selectedDate1 : "-"}
                                  </div>
                                  <div className="col month-scroll " onWheel={handleMonthScroll1}  >
                                    {default3 ? months[selectedMonthIndex1] : "-"}
                                  </div>
                                  <div className="col year-scroll " onWheel={handleYearScroll1}>
                                    {default6 ? toYear : "-"}
                                  </div>
                                </div>
                              </div>
                            </CardBody>
                          </Card>

                        </div>

                        //from month date and year
  const handleMonthScroll = (event: React.WheelEvent<HTMLDivElement>) => {
  const newMonthIndex = selectedMonthIndex + (event.deltaY > 0 ? 1 : -1);
    if ((newMonthIndex >= new Date().getMonth())&& (newMonthIndex <=11)) {
      setSelectedMonthIndex(newMonthIndex);
      setDefault1(true)

    }
  };

  const handleDateScroll = (event: React.WheelEvent<HTMLDivElement>) => {
    const newDate = selectedDate + (event.deltaY > 0 ? 1 : -1);
  if (newDate >= new Date().getDate() && newDate <= 31) {
      setSelectedDate(newDate);
      setDefault2(true)

    }
  };
  const handleYearScroll = (event: React.WheelEvent<HTMLDivElement>) => {
    const newYear = fromYear + (event.deltaY > 0 ? 1 : -1);

    if (newYear >= new Date().getFullYear()) {
      setFromYear(newYear);
      setDefault5(true);

    }
  };

// to month date and years
  const handleMonthScroll1 = (event: React.WheelEvent<HTMLDivElement>) => {
    const newMonthIndex1 = selectedMonthIndex1 + (event.deltaY > 0 ? 1 : -1);
    if ((newMonthIndex1 >= 0) && (newMonthIndex1 <=11))
    {
      setSelectedMonthIndex1(newMonthIndex1);
      setDefault3(true);
    }
  };

  const handleDateScroll1 = (event: React.WheelEvent<HTMLDivElement>) => {
    const newDate1 = selectedDate1 + (event.deltaY > 0 ? 1 : -1);

    if (newDate1 >= 1 && newDate1 <= 31) {
      setSelectedDate1(newDate1);
      setDefault4(true)
    }
  };

  const handleYearScroll1 = (event: React.WheelEvent<HTMLDivElement>) => {
    const newYear1 = toYear + (event.deltaY > 0 ? 1 : -1);

    if (newYear1 >= new Date().getFullYear()) {
      setToYear(newYear1);
      setDefault6(true);
    }
  };
*/

