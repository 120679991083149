import "./App.css";
import Actions from "./Partials/Actions";
import CalendarComponent from "./Partials/Calendar";
import Title from "./Partials/Title";
import Icon from '../../../../../components/icon/Icon';

interface ICalendarProps {
  setDate: any;
  date: any;
  selectRange: boolean;
  setSelectRange: any;
}

const CustomCalendar = (props: ICalendarProps) => {
  const { setDate, date, selectRange, setSelectRange } = props;
  return (
    <div className="psnAbs ofCvsZid" style={{display: "grid", placeItems: "center" }} >
      <CalendarComponent
        setDate={setDate}
        date={date}
        selectRange={selectRange} 
      />
      {/* <Actions
        setDate={setDate}
        date={date}
        selectRange={selectRange}
        setSelectRange={setSelectRange}
      /> */}
    </div>
  );
};
// style={{ width: "80vw", display: "grid", placeItems: "center" }}
export default CustomCalendar;
