import React, { SVGProps } from 'react';

const SvgNationalityUS = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg viewBox='0 0 24 24' fill='currentColor' className='svg-icon' {...props}>
            <path d='M0 0h24v24H0V0z' fill='none' />
            <path d='M0 0h24v24H0V0z' fill='none' />
            <path fill='#fff' d='M0 0h24v24H0V0z' />
            <path fill='#00247d' d='M0 16.6h24v3.8H0z' />
            <path fill='#fff' d='M0 20.4h24v3.8H0z' />
            <path fill='#b22234' d='M0 0h24v16.6H0z' />
            <rect x='10' y='2' width='4' height='20' fill='#777' />
        </svg>
    );
};

export default SvgNationalityUS;