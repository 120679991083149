import React, { SVGProps } from 'react';

const Svg17Mp = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg viewBox='0 0 24 24' fill='currentColor' className='svg-icon' {...props}>
            <path fill='none' d='M0 0h24v24H0z' />
            <path
                d='M5 19h14V5H5v14zm13-5.5V16c0 .55-.45 1-1 1h-2v1.5h-1.5v-6H17c.55 0 1 .45 1 1zm-6-8h3.5c.67 0 1.15.65.96 1.29L15 11.5h-1.75L14.62 7H12V5.5zm-5 0h3v6H8.5V7H7V5.5zm-1 8c0-.55.45-1 1-1h4.5c.55 0 1 .45 1 1v5H11V14h-1v3H8.5v-3h-1v4.5H6v-5z'
                opacity={0.3}
            />
            <path opacity={0.3} d='M15 14h1.5v1.5H15z' />
            <path d='M7.5 14h1v3H10v-3h1v4.5h1.5v-5c0-.55-.45-1-1-1H7c-.55 0-1 .45-1 1v5h1.5V14zM13.5 18.5H15V17h2c.55 0 1-.45 1-1v-2.5c0-.55-.45-1-1-1h-3.5v6zM15 14h1.5v1.5H15V14z' />
            <path d='M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14z' />
            <path d='M8.5 11.5H10v-6H7V7h1.5zM13.25 11.5H15l1.46-4.71c.19-.64-.29-1.29-.96-1.29H12V7h2.62l-1.37 4.5z' />
        </svg>
    );
};

export default Svg17Mp;
