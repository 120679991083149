import React from 'react';
import Header, { HeaderLeft } from '../../../layout/Header/Header';
import Avatar from '../../../components/Avatar';
import CommonHeaderRight from './CommonHeaderRight';
// import USERS from '../../../common/data/userDummyData';

const ProfilePageHeader = () => {
  const  currentUserFsNm = localStorage.getItem('authUserFsNm');
  const  currentUserLsNm = localStorage.getItem('authUserLsNm');
  const  currentUserpic = localStorage.getItem('authUserPic');
  return (
    <Header>
      <HeaderLeft>
        <div className='col d-flex align-items-center'>
          <div className='me-3'>
            <Avatar
              srcSet={currentUserpic ? currentUserpic : ''}
              src={currentUserpic ? currentUserpic : ''}
              size={48}
              color='primary'
            />
          </div>
          <div>
            <div className='fw-bold fs-6 mb-0'>{currentUserFsNm + ' ' + currentUserLsNm}</div>
            <div className='text-muted'>
              <small>Me</small>
            </div>
          </div>
        </div>
      </HeaderLeft>
      <CommonHeaderRight />
    </Header>
  );
};

export default ProfilePageHeader;
