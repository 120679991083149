import React, { SVGProps } from 'react';

const SvgHMobiledata = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg viewBox='0 0 24 24' fill='currentColor' className='svg-icon' {...props}>
            <path d='M0 0h24v24H0V0z' fill='none' />
            <path d='M15 11H9V7H7v10h2v-4h6v4h2V7h-2v4z' />
        </svg>
    );
};

export default SvgHMobiledata;
