import React, { SVGProps } from 'react';

const SvgSignalCellularConnectedNoInternet4Bar = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg viewBox='0 0 24 24' fill='currentColor' className='svg-icon' {...props}>
            <path d='M0 0h24v24H0V0z' fill='none' />
            <path d='M20 18h2v-8h-2v8zm0 4h2v-2h-2v2zM2 22h16V8h4V2L2 22z' />
        </svg>
    );
};

export default SvgSignalCellularConnectedNoInternet4Bar;
