import React, { SVGProps } from 'react';

const SvgSignalWifiStatusbar4Bar = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg viewBox='0 0 24 24' fill='currentColor' className='svg-icon' {...props}>
            <path d='M0 0h24v24H0V0z' fill='none' />
            <path d='M12 4C7.31 4 3.07 5.9 0 8.98L12 21 24 8.98A16.88 16.88 0 0012 4z' />
        </svg>
    );
};

export default SvgSignalWifiStatusbar4Bar;
