import React, { SVGProps } from 'react';

const Svg4KPlus = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg viewBox='0 0 24 24' fill='currentColor' className='svg-icon' {...props}>
            <path fill='none' d='M0 0h24v24H0z' />
            <path
                d='M5 19h14v-6.5h-1.5V14h-1v-1.5H15v-1h1.5V10h1v1.5H19V5H5v14zm6.5-10H13v2.25L14.75 9h1.75l-2.25 3 2.25 3h-1.75L13 12.75V15h-1.5V9zm-6 0H7v3h1.5V9H10v3h1v1.5h-1V15H8.5v-1.5h-3V9z'
                opacity={0.3}
            />
            <path d='M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 8.5h-1.5V10h-1v1.5H15v1h1.5V14h1v-1.5H19V19H5V5h14v6.5z' />
            <path d='M8.5 15H10v-1.5h1V12h-1V9H8.5v3H7V9H5.5v4.5h3zM13 12.75L14.75 15h1.75l-2.25-3 2.25-3h-1.75L13 11.25V9h-1.5v6H13z' />
        </svg>
    );
};

export default Svg4KPlus;
