// eslint-disable-next-line
import React, { useContext } from 'react';
import classNames from 'classnames';
import Header, { HeaderLeft, HeaderRight } from '../../../layout/Header/Header';
import useDarkMode from '../../../hooks/useDarkMode';
// import USERS from '../../../common/data/userDummyData';
// import AuthContext from '../../../contexts/authContext';

const DashboardBookingHeader = () => {
  const { darkModeStatus } = useDarkMode();
  // const { user } = useContext(AuthContext);
  const  currentUserName = localStorage.getItem('authUserFsNm');

  return (
    <Header>
      <HeaderLeft>
        <div className='d-flex align-items-center'>
          <div className='row g-4'>
            <div className='col-md-auto'>
              <div
                className={classNames('fs-3', 'fw-bold', {
                  'text-dark': !darkModeStatus,
                })}>
								Hi, {currentUserName}!
              </div>
            </div>
          </div>
        </div>
      </HeaderLeft>
      <HeaderRight>
        <div>
        </div>
      </HeaderRight>
    </Header>
  );
};

export default DashboardBookingHeader;
