import React, { SVGProps } from 'react';

const SvgSignalCellularAlt = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg viewBox='0 0 24 24' fill='currentColor' className='svg-icon' {...props}>
            <path d='M0 0h24v24H0V0z' fill='none' />
            <path d='M17 4h3v16h-3V4zM5 14h3v6H5v-6zm6-5h3v11h-3V9z' />
        </svg>
    );
};

export default SvgSignalCellularAlt;
