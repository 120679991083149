import React, { SVGProps } from 'react';

const SvgNotStarted = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg viewBox='0 0 24 24' fill='currentColor' className='svg-icon' {...props}>
            <path fill='none' d='M0 0h24v24H0z' />
            <path
                d='M12 4c-4.41 0-8 3.59-8 8s3.59 8 8 8 8-3.59 8-8-3.59-8-8-8zm-1 12H9V8h2v8zm1 0V8l5 4-5 4z'
                opacity={0.3}
            />
            <path d='M12 4c4.41 0 8 3.59 8 8s-3.59 8-8 8-8-3.59-8-8 3.59-8 8-8m0-2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 6H9v8h2V8zm6 4l-5-4v8l5-4z' />
        </svg>
    );
};

export default SvgNotStarted;
