import React, { SVGProps } from 'react';

const SvgSwapVerticalCircle = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg viewBox='0 0 24 24' fill='currentColor' className='svg-icon' {...props}>
            <path d='M0 0h24v24H0V0z' fill='none' />
            <path
                d='M12 4c-4.41 0-8 3.59-8 8s3.59 8 8 8 8-3.59 8-8-3.59-8-8-8zM6.5 9L10 5.5 13.5 9H11v4H9V9H6.5zm7.5 9.5L10.5 15H13v-4h2v4h2.5L14 18.5z'
                opacity={0.3}
            />
            <path d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-1-7V9h2.5L10 5.5 6.5 9H9v4zm4-2h-2v4h-2.5l3.5 3.5 3.5-3.5H15z' />
        </svg>
    );
};

export default SvgSwapVerticalCircle;
