import React, { SVGProps } from 'react';

const SvgRoofing = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg viewBox='0 0 24 24' fill='currentColor' className='svg-icon' {...props}>
            <path fill='none' d='M0 0h24v24H0z' />
            <path opacity={0.3} d='M11 16h2v2h-2z' />
            <path d='M13 18h-2v-2h2v2zm2-4H9v6h6v-6zm4-4.7V4h-3v2.6L12 3 2 12h3l7-6.31L19 12h3l-3-2.7z' />
        </svg>
    );
};

export default SvgRoofing;
