
import React from 'react';
import axios from 'axios';
import showNotification from '../components/extras/showNotification';
import Icon from '../components/icon/Icon';

let baseURL = 'http://localhost:5000';

let service = axios.create({
  withCredentials: true,
  baseURL: (process.env.NODE_ENV === 'development')? baseURL :'/'
});

service.interceptors.response.use(handleSuccess, handleError);

const handleSuccess = (response) => {
  return response;
};

const handleError = (error) => {
  switch (error.response.status) {
  case 401:
    //this.redirectTo(document, '/')
    console.log('error response 401',error.response);
    break;
  case 404:
    //this.redirectTo(document, '/404')
    console.log('error response 404',error.response);
    break;
  default:
    //this.redirectTo(document, '/500')
    console.log('error response other',error.response);
    break;
  }
  return Promise.reject(error);
};

export const getDataAPI = async (url) => {
  const res = await service.get( url, {
    'Content-Type': 'application/json',
  });//{withCredentials:true}
  return res;
};

export const getDataAPI2 = async (url) => {
  try {
    const response = await service.get( url, {
      'Content-Type': 'application/json',
    });//{withCredentials:true}
    console.log('res:',response);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const callNotification = async ( title, msg, icon, type ) => {
  showNotification(
    <span className='d-flex align-items-center'>
      <Icon icon={icon} size='lg' className='me-1' />
      <span>{title}</span>
    </span>,
    msg,
    type
  );
};

/*
export const getDataAPI = async (url,body) => {
    const res = await service.get( url, body, {
      'Content-Type': 'application/json',
    });//{withCredentials:true}
    return res;
};
*/
export const postDataAPI = async (url, body) => {
  const res = await service.post( url, body, {
    'Content-Type': 'application/json',
  });
  return res;
};

export const postDataAPI2 = async (url, body) => {
  try {
    const response = await service.post(url, body, {
      'Content-Type': 'application/json',
    });
    return response.data;
  } catch (error) {
    callNotification('Internal server error', error.response.data.errorMessage, 'Warning', 'danger');
    return error.response.data;
  }
};

export const putDataAPI = async (url, post, token) => {
  const res = await service.put(`/api/${url}`, post, {
    headers: { Authorization: token },
  });
  return res;
};

export const patchDataAPI = async (url, post, token) => {
  const res = await service.patch(`/api/${url}`, post, {
    headers: { Authorization: token },
  });
  return res;
};

export const deleteDataAPI = async (url ) => {
  const res = await service.delete( url , {
    'Content-Type': 'application/json',
  });
  return res;
};

export const putToS3 = async(path, payload, options, callback)=>{
  await service.put(path, payload, options).then((response)=>{
    callback(null, response.data);
  }).catch(error=>{
    callback(error.response, null);
  });
};
