import React, { SVGProps } from 'react';

const SvgUnsubscribe = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg viewBox='0 0 24 24' fill='currentColor' className='svg-icon' {...props}>
            <path d='M0 0h24v24H0V0z' fill='none' />
            <path
                d='M18.99 5H5l7 3.5zm.01 8.05V7l-7 3.5L5 7v8h10.35c.56-1.18 1.76-2 3.15-2 .17 0 .34.03.5.05z'
                opacity={0.3}
            />
            <path d='M20.99 14.04V5c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h10.05c.28 1.92 2.1 3.35 4.18 2.93 1.34-.27 2.43-1.37 2.7-2.71.25-1.24-.16-2.39-.94-3.18zm-2-9.04L12 8.5 5 5h13.99zm-3.64 10H5V7l7 3.5L19 7v6.05c-.16-.02-.33-.05-.5-.05-1.39 0-2.59.82-3.15 2zm5.15 2h-4v-1h4v1z' />
        </svg>
    );
};

export default SvgUnsubscribe;
