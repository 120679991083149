import React, { useState, useContext, useEffect, KeyboardEvent} from 'react';
import { useNavigate } from 'react-router-dom';
// import PropTypes from 'prop-types';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Label from '../../../components/bootstrap/forms/Label';

// import { Link } from 'react-router-dom';
import Button from '../../../components/bootstrap/Button';
import useDarkMode from '../../../hooks/useDarkMode';
import AuthContext from '../../../contexts/authContext';
import USERS from '../../../common/data/userDummyData';
import { demoPagesMenu } from '../../../menu';
import { postDataAPI2 } from '../../../helpers/httpUtils';
import showNotification from '../../../components/extras/showNotification';
import Icon from '../../../components/icon/Icon';
// import AuthLogin from '../../../common/Auth/auth';

const LoginHeader = () => {
  return (
    <>
      <div className='col-12 px-0'>
        <div className='text-center h1 fw-bold mb-2'>Welcome Back!</div>
        <div className='text-center h4 clrGrey text-capitalize mb-0'>Sign in to continue!</div>
      </div>
    </>
  );
};

const AdminLogin = () => {

  const { setUser } = useContext(AuthContext); //, setAuthPic, setAuthEmail, setAuthId
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ locTitle, setLocTitle] = useState<string>('');
  // const [ locBgImg, setLocBgImg ] = useState<string>('');
  const [ email, setEmail ] = useState<string>('');
  const [ pwd, setPwd ] = useState<string>('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { darkModeStatus, setDarkModeStatus } = useDarkMode();

	
  useEffect(() => {
    //setDarkModeStatus(!darkModeStatus);
    setDarkModeStatus(false);
  },[]);

  const CallAdminLoginApi = async () => {
    const isContentValid = await validateContents();
    if(isContentValid){
      // const adminUserDetails = await postDataAPI2('/auth/create-login-admin', { 'email': email, 'pwd': pwd });
      const adminUserDetails = await postDataAPI2('/auth/login-admin', { 'email': email, 'pwd': pwd });
      // console.log('admin user detaild:',adminUserDetails);
      if(adminUserDetails.statusCode === 200) {
        const userDetails = adminUserDetails.details;
        if (setUser) {
          USERS.CURRENTUSER.userId = userDetails.userID;
          USERS.CURRENTUSER.email =  userDetails.email;
          USERS.CURRENTUSER.username = userDetails.firstName;
          USERS.CURRENTUSER.src =  userDetails.picture;
          USERS.CURRENTUSER.srcSet =  userDetails.picture;
          USERS.CURRENTUSER.surname = userDetails.lastName;
          USERS.CURRENTUSER.name =  userDetails.firstName;
          setUser(USERS.CURRENTUSER.username);
          localStorage.setItem('authUserFsNm', userDetails.firstName);
          localStorage.setItem('authUserLsNm', userDetails.lastName);
          localStorage.setItem('authUserId', userDetails.userID);
          localStorage.setItem('authUserEmail', userDetails.email);
          localStorage.setItem('authUserPic', userDetails.picture);
          localStorage.setItem('isAdmin', userDetails.isAdmin);
        }
        if(userDetails.oldUser){
          showNotification(
            <span className='d-flex align-items-center'>
              <Icon icon='Beenhere' size='lg' className='me-1' />
              <span>Welcome back</span>
            </span>,
            'Your old account is retrived.',
            'success'
          );
        }
        //setDarkModeStatus(!darkModeStatus);
        navigate(`../${demoPagesMenu.location.subMenu.boxedSingle.path}`);
      } 
    } 
  }; 
  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      CallAdminLoginApi();
    }
  };
  const validateContents = async () => {
    if (email.trim() === '') {
      callNotification('Empty email','Email can\'t be empty.','Warning', 'danger');
      return false;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      callNotification('Invalid Email','Email can\'t be empty.','Warning', 'danger');
      return false;
    }

    if(pwd.trim() === '' ){
      callNotification('Empty password.','Password can\'t be empty.','Warning', 'danger');
      return false;
    }

    return true;
  };

  const callNotification = async ( title: string, msg: string, icon?: string, type?: string,  ) => {
    showNotification(
      <span className='d-flex align-items-center'>
        <Icon icon={icon} size='lg' className='me-1' />
        <span>{title}</span>
      </span>,
      msg,
      type
    );
  };

  const navigate = useNavigate();
		  
  return (
    <PageWrapper
      isProtected={false}
      title={'AdminLogin'}
      className={classNames('px-0 position-relative')}>
				
      <Page className='p-0 lgnCdPnl'>
        <div className='row m-0 h-100 align-items-center justify-content-center'>
          <div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container px-3'>
            <Card className='shadow-3d-dark bgBk1ClrWht brd30 mt-5 im_mb5 tpLtRtBf0 tpLtRtAf0' data-tour='login-page'>
              <CardBody className='vt-dWp-center ht150'>
								
                {locTitle.trim() !== '' ? 
								    <div className='col-12 px-0'>
			    						<div className='text-center h1 fw-bold mb-2'>{locTitle}</div>
				    					<div className='text-center h4 clrGrey text-capitalize mb-0'>See who is here now</div>
					    			</div> 
                  : 
                  <LoginHeader />
                }
              </CardBody>
            </Card>
            <Card className='shadow-3d-dark bgBk1ClrWht brd30 tpLtRtBf0 tpLtRtAf0 py-5' data-tour='login-page'>
              <CardBody className='lgnCdPnlHt'>
                <div className='col-12 px-0 hr-d-flex-center'>
                  <div className='col-auto px-0 wt250'>
                    <div className='col-12 px-0'>
                      <FormGroup>
                        <Label
                          className={ 'col-12 px-0 clrWht mb-2' }
                          // htmlFor={ '' }
                        >
                                                    Email
                        </Label>
                        <Input
                          className='lgAdmFmCtrl mb-4'
                          placeholder='Email'
                          type='email'
                          value={email}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value)}
                          onKeyPress={handleKeyPress}
                        />
                        <Label
                          className={ 'col-12 px-0 clrWht mb-2' }
                          // htmlFor={ '' }
                        >
                                                    Password
                        </Label>
                        <Input 
                          className='lgAdmFmCtrl mb-5'
                          placeholder='Password'
                          type='password'
                          value={pwd}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPwd(event.target.value)}
                          onKeyPress={handleKeyPress}
                        />
                        <Button
                          color={'primary'}
                          type={ 'submit' }
                          className={classNames('w-100 px-5 py-3 text-center clrWht mb-3')}
                          onClick={() =>{CallAdminLoginApi();}}
                        >
                                                    Sign in
                        </Button>
                        {/* <Button
                                                    color={'dark'}
                                                    isLink={ true }
                                                    className={classNames('w-100 px-5 py-3 text-center clrWht bgHrGreyWht')}>
                                                    Forgot My Password
                                                </Button> */}
                      </FormGroup>
                    </div>
                  </div>
                </div>	
              </CardBody>
            </Card>
          </div>
        </div>
      </Page>
      {/* {locBgImg.trim() !== '' ? 
			    <div className='lgnBgImg'>
				    <img className="objfitCvr" src={locBgImg} alt='Login Screen' width='100%' height='100%' />
			    </div> 
                :  */}
      <div className="lgnBgImg">
        <img className="objfitCvr" alt="Login Screen" src="https://app.ralu.co/assets/client/img/admin/Ralu_Admin.png" width="100%" height="100%"/>
      </div> 
      {/* } */}
    </PageWrapper>
  );
};

export default AdminLogin;
