import React, { SVGProps } from 'react';

const SvgEMobiledata = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg viewBox='0 0 24 24' fill='currentColor' className='svg-icon' {...props}>
            <path d='M0 0h24v24H0V0z' fill='none' />
            <path d='M16 9V7H8v10h8v-2h-6v-2h6v-2h-6V9h6z' />
        </svg>
    );
};

export default SvgEMobiledata;
